.boutonEnsavoir{
    box-sizing: border-box;

    width: 100%;
    height: 45px;

    margin-top: 5vh;

    /* GRIS/Black Transparence 3% */

    background: #F5F5F5;
    border: 1px solid #E4E4E4;
    cursor: pointer;
}

.textEnsavoir{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    margin-top: 12.5px;
    color: #000000;  
}

.iconDescente{
    margin-top: 10px;
    margin-left: -5%;
}

.dropdownStyle{
    /* paragraphe */

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    align-items: center;

    color: #000000;

    display: "none";
    color: blue;

    /* Inside auto layout */

    margin-top: -10px;
    flex: none;
    order: 1;
    flex-grow: 0;
}