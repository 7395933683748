.row .col-12 .SousTitre{
    margin-left: 0px;
    display: flex;
    align-items: center;
    margin-bottom: 1vh;
}

.row .col-12 .SousTitre .h61{
    color: #024C4A;
    margin-left: 2vh;
    margin-top: 2px;
}   

.Locaux .h61{
  color: #024C4A;
  margin-left: 2vh;
  margin-top: 2px;
} 

.h61 .vert{
  color: #024C4A;
} 

.Locaux{
  margin-left: 0px;
  display: flex;
  align-items: center;
  margin-bottom: 1vh;
}

.LocauxCol{
  margin-bottom: 3vh;
}



.row .justify-content-end .custom-button .droit{
    position: relative;
    margin-bottom: 5vh;
    background-color: bisque;
}

.row .col-12 .SousTitre .h51 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #024C4A;
    margin-left: 1vh;
    margin-top: 2px;
  }

.custom-button-droit{
    background-color: white;
    border: 1px solid black;
    border-radius: 0;

    margin-left: -2px;
    margin-top: -18vh;

    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
    gap: 10px;


    /* GRIS/BLACK */
    color: black;
    border-radius: 0px;
    height: 43px;
    text-align: center;

    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 12px;
    line-height: 43px;
    text-transform: uppercase;
}
  

.custom-button {
    background-color: white;
    border: 1px solid black;
    border-radius: 0;


    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 12px;
    gap: 10px;
    margin: 1vh;


    /* GRIS/BLACK */
    color: black;
    border-radius: 0px;
    height: auto;
    text-align: center;

    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 12px;
    line-height: 43px;
    text-transform: uppercase;
  }
  

.Bouton{
  margin-top: 5vh;
}

.compteur{
  margin-top: 1vh;
  margin-bottom: 5vh;
}
