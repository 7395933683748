* {
  box-sizing: border-box;
  }

  .unit{
  margin-left: 10px;
  }

  .normal input[type=text] 
  {
  width: 100%;
  padding: 12px;
  border: 1px solid black; /* Bordure noire de 1px */
  border-radius: 0px;
  resize: vertical;
  outline: none; /* Suppression de l'effet de focus */
  }

  
  /* input[type=text],
  select,
  textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid black; 
  border-radius: 0px;
  resize: vertical;
  outline: none; 
  } */
  
  input[type=text]:focus {
  border: 1px solid black; /* Bordure noire de 1px */
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.311); /* Ajout d'une ombre noire */
  }
  
  input[type=password],
  select,
  textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid black; /* Bordure noire de 1px */
  border-radius: 0px;
  resize: vertical;
  outline: none; /* Suppression de l'effet de focus */
  }
  
  input[type=password]:focus {
  border: 1px solid black; /* Bordure noire de 1px */
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.311); /* Ajout d'une ombre noire */
  }

  .col-25 label {
  padding: 0px 3px 0px 0;
  position: relative;
  background-color: white;
  padding-left: 3px;
  margin-bottom: -8px;
  z-index: 99;
  transition: top 0.3s ease;
  transition: left 0.4s ease;
  }
  
  .label-up {
  top: -15px;
  left: -18px;
  }
  
  input[type=submit] {
  background-color: #04AA6D;
  color: white;
  padding: 12px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  float: right;
  }
  
  input[type=submit]:hover {
  background-color: #45a049;
  border: 1px solid #ccc;
  }
  
  .container {
  border-radius: 5px;
  padding: 20px;
  }
  
  .col-25 {
  margin-top: 3%;
  margin-left: 16px;
  position: relative;
  margin-bottom: -10px;
  }
  
  .col-75 {
  margin-bottom: 3%;
  width: 90%;
  margin-top: -18px;
  }
  
  .row::after {
  content: "";
  display: table;
  clear: both;
  }
  
  input[type=text]::placeholder {
  font-style: italic;
  text-align: right;
  font-family: 'Montserrat' sans-serif;
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  }
  
  input[type=text]:focus::placeholder {
  color: transparent;
  }

  input[type=password]::placeholder {
  font-style: italic;
  text-align: right;
  font-family: 'Montserrat' sans-serif;
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  }
  
  input[type=password]:focus::placeholder {
  color: transparent;
  }

  /* Gestion d'erreur */

  .label-error label {
  color: red; /* Texte du label en rouge lors d'une erreur */
  }
 
  .erreur input[type=text] 
  {
  width: 100%;
  padding: 12px;
  border: 1px solid red; /* Bordure noire de 1px */
  border-radius: 0px;
  resize: vertical;
  outline: none; /* Suppression de l'effet de focus */
  }
  

  /* Responsive */
  
  @media screen and (max-width: 600px) {
  .col-25,
  .col-75,
  input[type=submit] {
    width: 100%;
    margin-top: 0;
  }
  }
  