.footer {
    background-color: black;
    color: white;
    padding: 8px 16px;
    font-size: 12px;
    width: 100%;
    bottom: 0;
  }

.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    height: 50px;
}

.footer-div {
    display: flex;
    align-items: center; /* Centre le texte à l'intérieur */
  }

.footer-ul {
    display: flex;
    align-items: center; /* Centre le texte à l'intérieur */
  }

.footer-left {
    margin: 0;
  }
  
.footer-right {
    color: white;
    text-decoration: none;
}

.footer-right:hover {
    text-decoration: underline;
  }
  


.footer nav ul {
list-style: none;
padding: 0;
margin-top: 4px;
display: flex;
justify-content: center;
gap: 16px;
}

.footer nav ul li a {
color: white;
text-decoration: none;
}

.footer nav ul li a:hover {
text-decoration: underline;
}
  
  

  

  
  
  