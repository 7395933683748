.GrasCarre{
    text-align: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-left: 5px; 
    height: 50px;
  }

  .ajout-input {
    width: 20px; 
  }
  
  .titreCarre{
    text-align: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    margin-left: 5px;
    justify-content: center;
    height: 50px;
  }
  
  .LogoCarre img{
    height: 50px;
  }
  
  .LogoCarre{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  .CarreLogo{
    margin-left: 2px;
    padding: 2vh;
    margin-top: 1vh;
    border: 1px solid black;
    cursor: pointer;
    align-items: end;
    height: 100%
  }

  .CarreLogoInput2{
    margin-left: 5px;
    padding-top: 3vh;
    padding-bottom: 3vh;
    margin-top: 5vh;
    border: 1px solid black;
    cursor: pointer;
    align-items: end;
  }

  .Check{
    position: absolute;
    margin-top: -8px;
    margin-left: -5px;
  }

  .CarreLogo.isChecked {
    box-shadow: -6px 6px 0px #68C4A5;
  }

  .KM{
    margin-top: 12px;
    padding-right: 5px;
    text-align: left;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    margin-left: 5px;
  }

  .GrasCarre .None{
    cursor: auto;
  }

  .CarreLogo .ajout-input-container{
    width: 90%;
    margin-left: 5%;
    height: 50px;
    justify-content: end;
    align-items: end;
  }

  .CarreLogoInput2 .ajout-input-container{
    width: 100%;
    height: 50px;
    justify-content: end;
    align-items: end;
  }

  .CarreLogoInput2 .KM{
    margin-top: 20px;
    padding-right: 0px;
    text-align: left;
  }

  .inputCL{
    display: flex;
    /* width: 90px; */
    justify-content: center;
  }

  .inputCL .ajout-input{
    width: 40px;   
    padding: 0px;
  }

  .inputCL2{
    margin-top: 5px;
    display: flex;
    /* width: 90px; */
    justify-content: center;
  }

  .inputCL2 .ajout-input{
    width: 35px;   
    padding: 0px;
  }
  
  .inputCL2 .KM{
    font-size: 10px;
    margin-left: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }
  

  .secondeMain{
    opacity: 0.3;
  }