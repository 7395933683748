.Compteur {
    display: flex;
    margin-top: 2vh;
    flex-wrap: wrap; /* Ajout de flex-wrap pour permettre le retour à la ligne */
  }
  
  .Compteur .ajout-input-container {
    margin-left: 12px;
    margin-right: 20px;
    margin-bottom: 8px; /* Ajout de marge inférieure pour la séparation des lignes */
  }
  
  .p4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
    flex-wrap: wrap; /* Ajout de flex-wrap pour permettre le retour à la ligne */
  
    @media (max-width: 680px) {
      font-size: 12px; /* Ajustement de la taille de police pour les téléphones */
      line-height: 16px; /* Ajustement de la hauteur de ligne pour les téléphones */
    }
  }
  
  
  .Compteur .ajout-input {
    padding: 0px;
    width: 30px;
  }
  
  .unitCompteur {
    margin-left: 10px;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #8C8C8C;
  }
  