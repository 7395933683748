.cookie-banner {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 220px;  
    background: #fff;
    padding: 15px; 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    font-family: Arial, sans-serif;
    text-align: center;
    z-index: 9999;
  }
  
  .cookie-content h2 {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .cookie-content p {
    font-size: 8px;
    color: #333;
  }
  
  .cookie-links {
    margin-top: 5px;
    font-size: 8px;
    color: #a57e2b;
  }
  
  .cookie-links a {
    color: #a57e2b;
    text-decoration: none;
    font-weight: bold;
  }
  
  .cookie-links a:hover {
    text-decoration: underline;
  }
  
  .cookie-button {
    display: inline-block;
    margin-top: 9px;
    padding: 5px 9px;
    border: 0.5px solid black;
    background: none;
    cursor: pointer;
    font-size: 8px;
    font-weight: bold;
    transition: all 0.3s ease;
  }
  
  .cookie-button.accept {
    background: black;
    color: white;
  }
  
  .cookie-button.decline {
    background: white;
    color: black;
  }
  
  .cookie-button.preferences {
    background: white;
    color: black;
  }
  
  .cookie-button:hover {
    opacity: 0.8;
  }
  

  
  .cookie-preferences h3 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .cookie-button.close {
    display: block;
    margin-top: 15px;
    background: black;
    color: white;
  }
  