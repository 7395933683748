.h69{
    position: relative;
    
    /* H3 */
    font-family: 'Montserrat' sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 28px;
    /* identical to box height */
    display: flex;
    align-items: center;
    
    /* PRIMAIRE/GREEN */
    color: #024C4A;
    margin-bottom: 3vh;
    margin-top: 5vh;
    }
