.Connection .row {
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
}

.Connection .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    gap: 1rem; /* Adds space between buttons */
}

.Connection .button-container .button-wrapper {
    width: auto;
    max-width: 200px; /* Adjust as needed */
}

.bottom-bouton{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 7vh;
}