.row .col-12 .SousTitre{
    margin-left: 0px;
    display: flex;
    align-items: center;
    margin-bottom: 1vh;
}

.row .col-12 .SousTitre .h61{
    color: #024C4A;
    margin-left: 2vh;
    margin-top: 2px;
}   

.row .justify-content-end .custom-button .droit{
    position: relative;
    margin-bottom: 5vh;
    background-color: bisque;
}

.row .col-12 .SousTitre .h51 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #024C4A;
    margin-left: 1vh;
    margin-top: 2px;
  }

.bottom-bouton{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.custom-button-droit{
    background-color: white;
    border: 1px solid black;
    border-radius: 0;

    margin-left: -2px;
    margin-top: -18vh;

    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
    gap: 10px;


    /* GRIS/BLACK */
    color: black;
    border-radius: 0px;
    height: 43px;
    text-align: center;

    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 12px;
    line-height: 43px;
    text-transform: uppercase;

}
  

.custom-button {
    background-color: white;
    border: 1px solid black;
    border-radius: 0;


    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 12px;
    gap: 10px;
    margin: 1vh;


    /* GRIS/BLACK */
    color: black;
    border-radius: 0px;
    height: 43px;
    text-align: center;

    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 12px;
    line-height: 43px;
    text-transform: uppercase;

  }
  
  /* .h61{
    margin-top : 5vh;
  } */


  .h71{
    /* 7/14 repas indiqués */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;


  /* GRIS/BLACK */
  color: #000000;
  }

  .popup{
    margin-top: 2vh;
  }

  .bouton{
    margin-top: 5vh;
  }

  .conso .h61{
    margin-top: 2vh;
  }



  .boutonL3{
    align-items: end;
    display: flex;
    margin-top: 3vh;
    margin-left: 72%;
}

.Cadre{
    border: 1px solid #000000;
    box-shadow: -6px 6px 0px #FBAB3B;
    padding: 5vh;
}

.etiquette{
  height: 150px;
  padding-left: 50px;
}

  /* Styles for small tablet and phone */
  @media only screen and (max-width: 1000px) {
    .h71{
      /* 7/14 repas indiqués */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  
  
    /* GRIS/BLACK */
    color: #000000;
    margin-top: 2vh;
    }

    .etiquette{
      height: auto;
      padding-left: 0px;
      margin: 5%;
      width: 90%;
    }

    .imageEtiquette{
      width: 100%;
    }
  }