.h79{
    position: relative;
    
    /* identical to box height */
    display: flex;
    align-items: center;
    justify-content: center;

    /* Avez-vous voyager en avion cette année ? */

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;

    /* GRIS/BLACK */
    color: #000000;


    }
