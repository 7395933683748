:root {
  --black: #000000;
  --cultured-pearl: #f5f5f5;
  --evening-sea: #024c4a;
  --green-sheen: #68c4a5;
  --mercury: #e4e4e4;
  --white: #ffffff;
  --yellow-orange: #fbab3b;
}

.h2.back {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15vw; /* Taille de police responsive en fonction de la largeur de l'écran */
  line-height: 15vw; /* Hauteur de ligne responsive en fonction de la largeur de l'écran */
  font-feature-settings: 'kern' off;
  color: rgba(0, 0, 0, 0.03);

  /* padding-left: 45vh;
  padding-top: 32vh;
  position: absolute; */
  z-index: 0; /* Assurez-vous que le titre est en arrière-plan */
  overflow: hidden; /* Cache tout ce qui dépasse de la largeur de l'écran */
  width: 100%; /* Assurez-vous que le titre s'étend sur toute la largeur de l'écran */
  white-space: nowrap; /* Empêche le texte de passer à la ligne suivante */
}

.h11 {
  color: var(--white);
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2vw; /* Taille de police responsive en fonction de la largeur de l'écran */
  line-height: 2.6vw; /* Hauteur de ligne responsive en fonction de la largeur de l'écran */
  text-transform: uppercase;
  font-weight: lighter;
}

.h21 {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 6vw; /* Taille de police responsive en fonction de la largeur de l'écran */
  line-height: 6.5vw; /* Hauteur de ligne responsive en fonction de la largeur de l'écran */
}

.h31 {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 5.3vw; /* Taille de police responsive en fonction de la largeur de l'écran */
  line-height: 5.8vw; /* Hauteur de ligne responsive en fonction de la largeur de l'écran */
  margin-top: 1vh;
  margin-bottom: 2vh;
}

.h31 .vert {
  color: #024c4a;
}

.h41 .vert {
  color: #024c4a;
}

.h3.jeremy {
  font-family: 'Montserrat' sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 4.7vw; /* Taille de police responsive en fonction de la largeur de l'écran */
  line-height: 4.5vw; /* Hauteur de ligne responsive en fonction de la largeur de l'écran */
  letter-spacing: -0.01em;
  font-weight: lighter;
}

.h41 {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 4vw; /* Taille de police responsive en fonction de la largeur de l'écran */
  line-height: 4.4vw; /* Hauteur de ligne responsive en fonction de la largeur de l'écran */
  text-transform: unset;
  letter-spacing: 0;
}

.h61 {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 4vw; /* Taille de police responsive en fonction de la largeur de l'écran */
  line-height: 4.6vw; /* Hauteur de ligne responsive en fonction de la largeur de l'écran */
}

.p1 {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.6vw; /* Taille de police responsive en fonction de la largeur de l'écran */
  line-height: 4vw; /* Hauteur de ligne responsive en fonction de la largeur de l'écran */
  margin-top: 1vh;
}

.button1 {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 3.2vw; /* Taille de police responsive en fonction de la largeur de l'écran */
  line-height: 3.5vw; /* Hauteur de ligne responsive en fonction de la largeur de l'écran */
  text-align: center;
}

.caption1 {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 4vw; /* Taille de police responsive en fonction de la largeur de l'écran */
  line-height: 4.2vw; /* Hauteur de ligne responsive en fonction de la largeur de l'écran */
  font-variant: all-small-caps;
}

@media (min-width: 576px) {
  .h2.back {
    font-size: 80px;
    line-height: 80px;
  }
  .h11 {
    font-size: 18px;
    line-height: 23.4px;
  }
  .h21 {
    font-size: 24px;
    line-height: 38.4px;
  }
  .h31 {
    font-size: 20px;
    line-height: 24px;
  }
  .h3.jeremy {
    font-size: 32px;
    line-height: 38.4px;
  }
  .h41 {
    font-size: 18px;
    line-height: 21.6px;
  }
  .h61 {
    font-size: 14.4px;
    line-height: 18px;
  }
  .p1 {
    font-size: 10px;
    line-height: 18px;
  }
  .button1 {
    font-size: 10.8px;
    line-height: 13.5px;
  }
  .caption1 {
    font-size: 8px;
    line-height: 9.6px;
  }
}

@media (min-width: 1200px) {
  .h2.back {
    font-size: 180px;
    line-height: 180px;
  }
  .h11 {
    font-size: 24px;
    line-height: 31.2px;
  }
  .h21 {
    font-size: 33.6px;
    line-height: 54px;
  }
  .h31 {
    font-size: 25px;
    line-height: 33.6px;
  }
  .h3.jeremy {
    font-size: 44.4px;
    line-height: 54px;
  }
  .h41 {
    font-size: 18px;
    line-height: 24.8px;
  }
  .h61 {
    font-size: 16px;
    line-height: 18px;
  }
  .p1 {
    font-size: 16px;
    line-height: 20px;
  }
  .button1 {
    font-size: 14.4px;
    line-height: 18px;
  }
  .caption1 {
    font-size: 12px;
    line-height: 14.4px;
  }
}
