.logoplus{
  position: absolute;
  margin-left: 20px;
  width: 30px;
}

.titreBurger{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  font-variant: all-small-caps;
  
  color: #000000;
  margin-left: 50px;
}


.traiBurger{
  position: absolute;
  border-left: 2px solid black;
  height: 55px;
  width: 3px;
  margin-left: 44px;
  margin-top: -55px;
}
