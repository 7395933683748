.btn.btn-black {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 12px;
  gap: 10px;
  cursor: pointer;
  float: right;
  margin: 1vh;

  /* GRIS/BLACK */
  background: #000000;
  color: #ffffff;
  border-radius: 0px; /* Ajout d'un léger arrondi */
  height: 43px;
  text-align: center;

  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 12px;
  line-height: 1.2;
  text-transform: uppercase;
  font-weight: 400;

  transition: all 0.1s ease; /* Animation douce */
}

.btn.btn-black:hover {
  background: #000000;
  color: #ffffff;
  transform: scale(1.03); /* Légère mise en avant */
}
