.small-card2 {
    width: 180px;
    height: 180px;
    background: none;
    border: none;
    padding: 0;
    display: flex;
    justify-content: center;
    margin-top: 5vh;
    margin-right: 5vh;
    }

    .small-card2:hover {
    width: 180px;
    height: 180px;
    background: none;
    border: none;
    padding: 0;
    display: flex;
    justify-content: center;
    margin-top: 3vh;
    margin-right: 5vh;
    }
    
.small-card2 .rectangle {
    position: relative;
    background: #FFFFFF;
    border: 1px solid #000000;
    width: 180px;
    height: 150px;
    justify-content: center;
    justify-items: center;
    align-items: center;
    }
    
    .small-card2 .label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #000000;
    text-align: center;
    margin-top: 4vh;
    display: flex;
    justify-content: center;
    }
    
    .small-card2 .image {
    width: 40px;
    height: 40px;
    background-size: cover;
    background-position: center;
    margin-top: 3vh;
    margin-left: 70px;
    }
