.TitreGrand{   
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  /* identical to box height */
  color: #000000;
}


.TitrePetit{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */
  color: #000000;
}

.p3{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  font-variant: all-small-caps;
  color: #000000;   
}


.jaune{
  background: #EBE085;
  padding-top: 2vh;
}

.boutonL3{
  margin-left: 3vh;
}
