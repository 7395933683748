.checkmark {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #fff;
    border: 2px solid #000;
    margin-right: 5px;
  }
  
  .checkmark::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #000;
    display: none;
  }
  
  .checkbox label {
    margin-top: 1.5vh;
    margin-bottom: 1vh;
    cursor: pointer;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
  }
  
  input[type="checkbox"] {
    display: none;
  }
  
  input[type="checkbox"]:checked + .checkmark::after {
    display: block;
  }
  
  .checkbox{
    margin-top: 2vh;
    margin-bottom: 5vh;
  }

  /* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .checkbox.row {
    display: flex;
    flex-direction: column;
  }

  .checkbox.col-12 {
    width: 100%;
  }

  .checkbox label {
    margin-top: 12px; /* Adjust margin for smaller screens */
    margin-bottom: 2px; /* Adjust margin for smaller screens */
    font-size: 14px; /* Adjust font size for smaller screens */
  }

  .checkbox {
    margin-top: 2vh; /* Adjust margin for smaller screens */
    margin-bottom: 3vh; /* Adjust margin for smaller screens */
  }

}