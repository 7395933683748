/* Supprimez les styles existants pour le header */
.header {
    background-color: transparent;
    position: relative;
}

/* Styles pour le container principal */
.container-fluid {
    background-image: url("../img/rectangle-32.png");
    background-size: cover;
    background-position: center;
    padding: 0;
}


/* Styles pour la ligne */
.row {
    margin: 0;
}

/* Styles pour les colonnes */
.col-3,
.col-4,
.col-12 {
    padding: 0;
}

/* Styles pour le fond haut */
.rectangle-32 {
    width: 100%;
    height: auto;
}

/* Styles pour le trait blanc */
.rectangle-33 {
    background-color: white;
    height: 5px;
    width: 100%;
}

/* Styles pour le cadre du logo */
.frame-2 {
    background-color: white;
    box-shadow: 0px 2px 1px #00000040;
    padding: 1vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -40px;
}

/* Styles pour l'image du logo */
.image-4 {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    cursor: pointer;
}

/* Styles pour le titre */
.title{
    margin-top: 3vh;
}

/* Styles pour le sous-titre */
.agir-nos-cts {
    color: white;
    white-space: nowrap;
    margin-top: 3vh;
    margin-bottom: 3vh;
}

/* Media Query pour les appareils avec une largeur de moins de 800px */
@media (max-width: 768px) {

    .agir-nos-cts {
        font-size: 4vh;
        margin-top: 2vh;
        margin-bottom: 1vh;
        /* width: 25vw; */
    }

    .title{
        margin-top: 2vh;
    }

    .rectangle-33 {
        height: 3px;
    }

    .container-fluid{
        width: 100%;
        margin : 0px;
    }

    .header {
        background-color: transparent;
        position: relative;
        margin: 0px;
        width: 100%;
    }

    .container{
        width: 100%;
        margin : auto;
    }


}


@media (max-width: 580px) {

    .agir-nos-cts {
        font-size: 4vh;
        margin-top: 2vh;
        margin-bottom: 1vh;
        /* width: 25vw; */
    }

    .title{
        margin-top: 2vh;
    }

    .rectangle-33 {
        height: 2px;
    }

    .frame-2 {
        margin-top: 1vh;
    }

    .container-fluid{
        width: 100%;
        margin : 0;
    }

    .header {
        background-color: transparent;
        position: relative;
        margin: 0;
        width: 100%;
    }

    .container{
        width: 100%;
        margin : 0;
    }

}
