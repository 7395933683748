.ajout-container {
    display: flex;
    align-items: center;
    margin-bottom: 5vh;
    margin-top : 2vh;
    margin-left: 12px;
  }

  .ajout-container-numerique {
    display: flex;
    align-items: center;
    margin-bottom: 5vh;
  }

  .ajout-container-numerique span{
    margin-left: 80px;
    text-align: center;
    margin-top: 15px;
  }
  
  .ajout-label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
  }
  
  .ajout-input-container {
    display: flex;
    align-items: center;
  }
  
  .ajout-button {
    padding: 4px 8px;
    border: 0px solid white;
    background-color: white;
    font-weight: bold;
    cursor: pointer;
  }
  
  .ajout-input {
    text-align: center;
    margin: 0 5px;
    width: 15px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
  }

 

  .ajout-container .ajout-input{
    width:  50px;
    border-bottom: 1px dashed #0000002c;
    border-top: white;
    border-left: white;
    border-right: white;
    border-radius: 0px;
    resize: vertical;
    outline: none; /* Suppression de l'effet de focus */
  }


  .ajout-container .ajout-input:focus{
    width:  50px;
    border-bottom: 1px dashed #0000005f;
    border-top: white;
    border-left: white;
    border-right: white;
    border-radius: 0px;
    resize: vertical;
    box-shadow: none;
    outline: none; /* Suppression de l'effet de focus */
  }

  
  .ajout-select {
    margin-left: 5px;
    width: auto;
    border: 0px solid white;
  }
  

.labelperso{
font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 20px;
/* identical to box height */

display: flex;
align-items: center;
padding-left: 12px;

/* GRIS/GRIS */

color: #8C8C8C;
}
  