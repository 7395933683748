.checkmark {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #fff;
    border: 2px solid #000;
    margin-right: 5px;
  }
  
  .checkmark::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #000;
    display: none;
  }
  
  .checkboxAjout label {
    margin-top: 15px;
    margin-bottom: 15px;
    cursor: pointer;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
  }
  
  input[type="checkbox"] {
    display: none;
  }
  
  input[type="checkbox"]:checked + .checkmark::after {
    display: block;
  }
  
  .checkboxAjout .ajout-input{
    width: 10vh;
    border: 0px;
  }

  .checkboxAjout{
    margin-top: 5vh;
    margin-bottom: 5vh;
  }


  .ajout-input[type=text] {
    border: 0px solid #fff;
    border-bottom: 1px dashed #00000051;
    margin-top: 10px;
    box-shadow: none;
  }
  
  .ajout-input[type=text]:hover {
    border: 0px solid #fff;
    border-bottom: 1px dashed #00000051;
    box-shadow: none;
  }

  .ajout-input[type=text]:focus{
    border-bottom: 1px dashed #0000005f;
    border-top: white;
    border-left: white;
    border-right: white;
    border-radius: 0px;
    resize: vertical;
    box-shadow: none;
    outline: none; /* Suppression de l'effet de focus */
  }