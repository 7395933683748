.containerT {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 7vh;
    margin-top: 1vh;
    }

    

    
    .circle {
    position: relative;
    width: 25px;
    height: 25px;
    margin: 10px;

    }
    
    .lineT {
    height: 1px;
    background: #000;
    opacity: 1;
    transition: opacity 0.3s ease;
    }
    
    .line-between {
    flex: 1;
    margin: 0 2px;
    background-color: rgba(0, 0, 0, 0.3);
    }
    
    .triangleT {
    position: absolute;
    bottom: -5px;
    left: calc(50% - 5px);
    width: 0;
    height: 0;
    margin-right: 0px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 8px solid #000;
    z-index: -1;
    }
    
    .line-down {
    position: absolute;
    top: calc(100% + 1px);
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid black;
    height: 30px;
    opacity: 0;
    transition: opacity 0.3s ease;
    margin-top: -2px;
    z-index: -1;
    border-style: dashed;
    }
    
    .textT {
    text-align: center;
    margin-top: 5px;
    padding-left: 20px;
    width: auto;
    position: absolute;
    top: calc(100% + 10px);
 
    transform: translateX(-50%);
    transition: margin-top 0.3s ease;
    margin: 10%;
    font-family: 'Montserrat' sans-serif;
    font-style: normal;
    font-weight: 400;
    
    font-size: 14px;
    line-height: 20px;
    }
    

    .circle:nth-child(2) .textT {
    font-weight: normal; /* Assurez-vous que le texte "Voiture" n'est plus en gras */
    }
    .H69 {
    font-family: 'Montserrat' sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    }
    
    .circle:first-child,
    .circle:nth-child(2) {
    width: 25px;
    height: 25px;
    }
    
    .circle:first-child::before,
    .circle:nth-child(2)::before {
    width: 6px;
    height: 6px;
    }

    
    .circle:nth-child(n+6) {
    background-color: #FFF;
    /* opacity: 0.2; */
    }
/* 
    .line-between:nth-child(2) {
    border-color: green;
    }

    .line-between:nth-child(3) {
    border-color: #FBAB3B;
    } */
    
    /* .line-between:nth-child(n+5) {
    opacity: 0.2;
    } */
    
    .circle:hover {
    cursor: pointer;
    }
    
    .circle:hover .textT {
    margin-top: 10px;
    }

/*     
    .circle:nth-child(3) .line-down {
    opacity: 1;
    } */
    
    /* .circle:nth-child(3) .textT {
    
    font-family: 'Montserrat' sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    }
     */

    

    
    .circle {
    position: relative;
    width: 25px;
    height: 25px;
    margin: 10px;
    }
    
    /* Styles for small tablet and phone */
    @media only screen and (max-width: 767px) {
    .containerT {
        flex-wrap: wrap;
        justify-content: space-evenly;
    }
    
    .circle {
        width: 15px;
        height: 15px;
        margin: 5px;
    }
    
    .textT {
        text-align: center;
        margin-top: 4vh;
        /* padding-left: 2vh; */
        margin: 10%;
        text-align: center;
        font-size: 0.9vh;
        line-height: 1.5vh;
        display: none;
    }
    
    .lineT {
        display: none;
    }

    .containerT {
        width: 100%;
    }
    }
    

