.bandG{
    background: #F5F5F5;
    box-shadow: 0px -10px 15px rgba(255, 255, 255, 0.25);
    border-radius: 0px 50px 0px 0px;
    margin: 0px;
    padding: 0px;
    margin-left: -12px;
}

.Band1{
    background-color: #68C4A5;
    margin-top: 5vh;
    margin-left: 0px;
    padding-left: -12px;
    padding-right: -12px;
    margin-right: 0px;
}

.bandG .col-3{
    display: flex;
    align-items: center;
    justify-content: center;
}

.Band1 .col-7{
    margin-top: 2vh;
    margin-left: 1vh;
}

.p2{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;

    /* GRIS/WHITE */
    margin-bottom: 0px;

    color: #FFFFFF;
}

.bandD{
    background: #F5F5F5;
    box-shadow: 0px -10px 15px rgba(255, 255, 255, 0.25);
    border-radius: 50px 0px 0px 0px;
    padding: 2vh;
}

.Band2 {
    background-color: #024C4A;
    margin-top: 50px;
    padding: 0px;
    margin-left: 0px;
}

.Band3{
    background-color: rgba(245, 245, 245, 1);
    padding: 2vh;
    margin-top: 3vh;
    margin-bottom: 3vh;
}





.bandD .col-4{
    display: flex;
    align-items: center;
    justify-content: center;
}

.bandD .col-8{
    display: flex;
    align-items: center;
    justify-content: center;
}


.bandA{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3vh;
}