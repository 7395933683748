.InputUnit{
    margin-top: -10px;
}

.unit{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 11px;
line-height: 13px;
display: flex;
align-items: flex-end;
color: black;
border: 0px solid black;
}

select{
    border: 0px solid black;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    display: flex;
    align-items: flex-end;
    color: black;
}

.unit-select{
    width: 80px;
}


    