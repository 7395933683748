.Avion {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2vh;
  }
  
  .containerLogo:hover {
    cursor: pointer;
  }
  
  .ImageAvion {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  


  /* Rendre le composant responsive */
  
  /* Media query pour les écrans de taille moyenne, comme les tablettes */
  @media (max-width: 768px) {
    /* Réduire la taille du titre pour s'adapter à l'écran de taille moyenne */

  
    /* Réduire la taille du sous-titre pour s'adapter à l'écran de taille moyenne */
    .h61.Avion {
      font-size: 12px;
    }
  
    /* Ajuster la largeur des colonnes pour une mise en page en colonnes sur les écrans de taille moyenne */
    .col-4,
    .col-6 {
      flex-basis: 50%;
      max-width: 50%;
    }
  }
  
  /* Media query pour les écrans plus petits, comme les smartphones */
  @media (max-width: 480px) {
    /* Réduire davantage la taille du titre pour s'adapter à l'écran plus petit */

  
    /* Réduire davantage la taille du sous-titre pour s'adapter à l'écran plus petit */
    .h61.Avion {
      font-size: 10px;
    }
  }
  