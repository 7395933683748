    /* ..small-card {
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    padding: 0;
    display: flex;
    justify-content: center;
    margin-top: 5vh;
    }
    
small-card .rectangle {
    position: relative;
    width: 35vh;
    height: 32vh;
    background: #FFFFFF;
    border: 1px solid #000000;
    }
    
    .small-card .label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #000000;
    text-align: center;
    margin-top: 7vh;
    display: flex;
    justify-content: center;
    }
    
    .small-card .image {
    width: 12vh;
    height: 12vh;
    background-size: cover;
    background-position: center;
    margin-left: 11.5vh;
    margin-top: 5vh;
    display: flex;
    justify-content: center;
    }
    
     */

     .small-card .custom-button{
        padding: 5px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;


    /* GRIS/BLACK */
        color: black;
        border-radius: 0px;
        height: 23px;
        text-align: center;

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 23px;
        text-transform: uppercase;

        margin-left: auto;
        margin-right: auto;
        background-position: center;
        background-size: cover;
        position: relative;
        display: flex;
     }

     
     .small-card {
        margin: 10px;
        padding: 2vh;
        padding-bottom: 20%;
        background-color: white;
        height: 180px;
        width: 100%;

        transition: all 0.1s ease;
    }

    .small-card .previous-choice {
        position: relative;
        height: 1px ;
        font-size: xx-small ;
    }

    .small-card:hover {
    margin-top: 2px;
    }
    
    .small-card .rectangle {
    width: 100%;
    height: 100%;
    }
    
    .small-card .label {
    margin-top: 2vh;
    margin-bottom: 2vh;
    }

    .small-card .label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #000000;
    text-align: center;
    justify-content: center;
    height: 40px;
    }
    
    .small-card .image {
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    background-position: center;
    background-size: cover;
    position: relative;
    display: flex;
    }
    

    .imageDiv{
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    }