.page{
    background-color: rgba(245, 245, 245, 1);
    position: relative;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    padding-top: 10vh;
    min-height: 100vh; /* Utilise 100vh pour occuper toute la hauteur de l'écran */

}

.Header {
    position: relative;
}


.Stepline {
    position: relative;
    height: 10%;
}

.contenue {
    position: relative;
    width: 80%;
    max-width: 1300px;
    margin-left: 10%;
    margin-right: 10%;
}

.contenue .sansstepline {
    position: relative;
    width: 80%;
    max-width: 1300px;
    margin-left: 10%;
    margin-right: 10%;
}

.conten {
    position: relative;
    width: 90%;
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20vh;
}

.sansstepline {
    top: 20vh;
}

.container {
    background-color: white;
}


.container-bas{
    background-color: white;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
}


.trai-noir{
    border: 2px solid black;
    margin-left: 12px;
    margin-top: 4vh;
    margin-bottom : 2vh;
}

.textTitre{
    margin-bottom: 2vh;
}

.carrelogo{
    margin-top : 2vh;
    margin-left: 12px;
    margin-right: 2vh;
    padding: 2vh;
    cursor: pointer;
}

.Carre{
    margin: 2vh;
}

.GrasCarre{
    text-transform:uppercase;
}

/* Media query pour les écrans de taille moyenne, comme les tablettes */
@media (max-width: 768px) {
    .contenue,
    .contenue .sansstepline,
    .conten {
        width: 90%;
        max-width: 100%;
        margin-left: 5%;
        margin-right: 5%;
    }

    .Header,
    .Stepline {
        height: auto;
    }

    .TitreBG{
        display: none;
    }

    .container {
        background-color: white;
        width: 80%;
        margin-left: 10%;
    }

    
}

/* Media query pour les écrans plus petits, comme les smartphones */
@media (max-width: 580px) {

    .Stepline,
    .conten,
    .sansstepline {
        position: relative;
        width: 90%;
        max-width: 100%;
        margin-left: 5%;
        margin-right: 5%;
    }

    .contenue,
    .contenue .sansstepline {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }

    .page{
        background-color: rgba(245, 245, 245, 1);
        position: relative;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
        padding-top: 0;
    }

    .container{
        background-color: white;
        width: 100%;
        margin-left: 0;
        padding-top: 5%;
    }
}



