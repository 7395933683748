.default-card-droit{
    position: relative;
    /* max-height: 50vh; */
    /* height : 20vh; */
    height : 25vh;
    margin-bottom: 5vh;
    /* max-height: 15vh; */
}
  
  .default-card-droit .rectangle-61 {
    position: relative;
    height: 25vh;
  
    /* max-height: 350px; */
    z-index: 1;
    /* max-height: 15vh; */
  }
  
  .default-card-droit .rectangle-78 {
    position: absolute;
    height: 100%;
    width: 100%;
    /* max-height: 350px; */
    margin-left: -1vh;
    margin-right: 1vh;
    margin-top: 1vh;
    background: #FFFFFF;
    border: 1px solid #000000;
    z-index: 2;
    padding: 5px;
    /* max-height: 15vh; */
  }
  
  .default-card-droit .rectangle-78 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .LogoAvionDiv {
    margin: 5% auto;
    width: 80px;
  }
  
  .TitreAvionDiv {
    width: 30%;
    text-align: center;
    margin-top: 10vh;
    margin-left: 35%;
    padding-left: 3%;
  }

  /* Style pour les sous-divs */
.subdiv {
  width: 100%;
  height: 2px;
  margin-bottom: 3px;
  background-color: #000;
}


.Chiffre{
  text-align: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 44px;
  
}

.SousChiffre{
  text-align: center;
  
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}


.emission-card {
  height: 400px; /* hauteur de la carte emission du popup */
}
  