* {
    box-sizing: border-box;
    }
 
    
    .liste .form-select {
    width: 100%;
    border: 1px solid black;
    border-radius: 0px;
    outline: none;
    box-shadow: none;
    text-align: right;
    padding-top: 12px;
    padding-bottom: 12px; 
    }
    
    .titre-list label {
    padding: 0px 3px 0px 0;
    position: relative;
    background-color: white;
    padding-left: 3px;
    z-index: 99;
    transition: top 0.3s ease;
    transition: left 0.4s ease;
    }
    
    label.label-up {
    top: -18px;
    }
    
    .liste {
    margin-top: -14px;
    border-radius: 0px;
    position: relative;
    }
    
    .form-control-list:focus {
    box-shadow: 0 0 2px black;
    }
    
    .titre-list {
    margin-top: 6px;
    margin-left: 16px;
    position: relative;
    }

    .form-group-list {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    }
    
    .form-control-list {
    width: 100%;
    border: 1px solid black;
    border-radius: 0px;
    outline: none;
    box-shadow: none;
    padding: 12px;
    }

    
    @media screen and (max-width: 600px) {
    .col-25,
    .col-75,
    input[type=submit] {
        width: 100%;
        margin-top: 0;
    }

    .form-control-list {
        max-width: none; /* Remise à zéro de la largeur maximale pour les petits écrans */
    }
    }
    

    