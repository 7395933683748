.BilanInt{
    margin-bottom: 5vh;
}

.containerB {
    height: 52px;
    border-bottom: 1px solid black;
    padding-top: 16px;
  }

  .containerC {
    height: 52px;
    border-bottom: 2px solid black;
    border-top: 2px solid black;
    padding-top: 9px;
    display: flex;
    vertical-align: middle;
    padding-top: 13px;
    align-items: center;
  }
  
  .contentB {
    display: flex;
    height: 100%;
  }
  
  .textB {
    display: inline-block;
    vertical-align: middle;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
  
  .imageCont {
    display: inline-block;
    margin-right: 15px;
    width: 30px;
    height: 30px;
    margin-top: -5px;
    vertical-align: middle;
  }
  
  .imageLogo {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
  }
  
  
  .icon-container {
    position: relative;
    width: 100%;
    padding-left: 95%;
    margin-top: -30px;
    vertical-align: middle;
  }

  .Icon{
    cursor: pointer;
  }
