.circle-bilan {
    display: flex;
    flex-direction: column; /* Ajout de cette ligne pour empiler l'icône et le texte */
    align-items: center;
    cursor: pointer;
    margin: 10px;
    }

    .circle-bilan-disconnect {
    display: flex;
    flex-direction: column; /* Ajout de cette ligne pour empiler l'icône et le texte */
    align-items: center;
    margin: 10px;
    }
    
    .text-bilan {
    margin-top: 5px; /* Espace entre l'icône et le texte */
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #000; /* Assurez-vous que la couleur est visible */
    text-align: center; /* Centre le texte sous l'icône */
    }

.bouton-bilan {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.connexion-container {
    right: 5%;
}
    