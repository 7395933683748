.show-more-text {
    color: #68C4A5; /* couleur du texte principal */
    cursor: pointer;
    }

.warning-message {
    display: flex;
    align-items: center;
    background-color: #f9f9f9;
    border: 1px solid #ddd; 
    border-radius: 8px; 
    padding: 16px; 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
