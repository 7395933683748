.chart {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 500px;
  padding-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  border-left: 2px solid #000;
  border-bottom: 2px solid #000;
  width: 100%;
  position: relative;
  margin-top: 5vh;
}


.Lab{
  display: flex;
  flex-direction:column;
  align-items:center;
}

.bar {
  display: flex;
  flex-direction:row;
  align-items:center;
  align-items:flex-end;
}
.bar .Col {
  width: 15px; 
  margin-right: 20px;
  position: relative;
}

.bar .ColAf {
  width: 15px; 
  position: relative;
}

.bar .Col-2 {
  overflow: hidden; /* Masque le contenu qui dépasse */
}


.labelBar {
  top: 510px;
  position: absolute;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

#container {
  width: 15px;
  height: 200px;
  background-color: #ccc;
}

/* Style pour les sous-divs */
.subdiv {
  width: 15px;
  height: 2px;
  margin-bottom: 3px;
  background-color: #000;
}

.legend-box .subdiv{
  width: 50px;
  margin-left: -15px;
}


.legend {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 15px;
  border: 1px solid #000;
}

.legend-box {
  position: relative;
  height: 20px;
  margin: 0px;
  border: 1px solid #000;
  overflow: hidden; /* Masque le contenu qui dépasse */
}

.row1{
  margin-bottom: 10px;
}

.legend-text {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}





/*.Col:hover .column-text{
  opacity: 1;
  font-weight: bold;
  font-weight: 900;
}

/*.bar .Col:hover::after {
  content: attr(data-height);
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 10px;
  background-color: red;
  border: 3px;
  border-radius: 5px;
  white-space: nowrap;
}*/



/*.ColAf:hover::after {
  content: attr(data-height);
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 10px;
  background-color: red;
  border: 3px;
  border-radius: 5px;
  white-space: nowrap;
}*/


.column-text {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  padding: 5px;
  white-space: nowrap;
  margin-bottom: 5px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  }



.Gras{
  text-align: start;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-left: 5px;
}

.titre{
  text-align: start;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  margin-left: 5px;
}

.LogoLigne1 img {
  height: 70px;
  width: 70px;
  margin-bottom: 2vh;
}

.LogoLigne1 {
  display: flex;
  align-items: start;
  justify-content: start;
  width: 70px;
  margin-right: 1px; /* Ajout d'une marge à droite pour espacer les images */
}

.images {
  display: flex;
  align-items: start;
}

.fraction-container {
  width: 70px;
  height: 70px;
  overflow: hidden;
  margin-right: 10px; /* Ajout d'une marge à droite pour la dernière image (fractionnaire) */
}

.fraction-image {
  display: block;
  object-fit: cover;
}

.Ligne1.row {
  display: flex;
  align-items: center;
}

.Ligne1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 4vh;
}


.traiLigne{
  position: absolute;
  border: 1px solid black;
  width: 49px;
  margin-left: -50px;
  z-index: 1;
  margin-top: 13px;
}

.logo{
  margin-top: 30px;
}


.text{
  margin-top: 30px;
}

.savoir{
  text-decoration: underline;
  cursor: pointer;
  margin-top: 15px;
}


.ContenueCardBilan{
  padding: 2vh;
}

.CarreBilanParticulier{
  background-color: #EBE085;
  height: 200px;
  justify-items: center;
  align-items: center;
  padding: 8%;
  margin-bottom: 3vh;
}

.CarreBilanParticulier .textCarreBilan{
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 3vh;
}

.column-title {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 11px;
  padding: 5px;
  white-space: nowrap;
  margin-bottom: 5px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
}
